import React, { useState } from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import "./ContactCard.scss"
import ReactMarkdown from "react-markdown"
import Modal from "react-bootstrap/Modal"
import OfficeForm from "@Components/forms/officecontact"
const ContactCard = props => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = event => {
    setShow(true)
  }
  return (
    <div className="contact-card">
      <h3>Contact Details</h3>
      <ul class="contact-box">
        {props.Address && (
          <li>
            <div className="icon-wrap">
              <i className="icon-building"></i>
            </div>
            <div className="contact-text">
              <p>
                <ReactMarkdown
                  source={props.Address?.replace(/,/g, ",<br/>")}
                  allowDangerousHtml
                />
              </p>
            </div>
          </li>
        )}
        {props.Contact_No && (
          <li>
            <div className="icon-wrap">
              <i className="icon-phone"></i>
            </div>
            <div className="contact-text">
              <Link href={"tel:+" + props.Contact_No}>{props.Contact_No}</Link>
            </div>
          </li>
        )}
        {props.Email && (
          <li onClick={() => handleShow()}>
            <div className="icon-wrap">
              <i className="icon-mail"></i>
            </div>
            <div className="contact-text">
              <a href="javascript:;" onClick={() => handleShow()}>
                {props.Email}
              </a>
            </div>
          </li>
        )}
      </ul>
      <Modal
        show={show}
        centered={true}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w modal-form modal-form-buy-sell-rent reviewPopup"
      >
        <Modal.Header closeButton className="contact-close-btn">
          <Modal.Title className="w-100">
            Email to {props.Branch_Location}, {props.County}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="form">
          <OfficeForm
            toemail={props.Email}
            branchPhoneNum={props.Contact_No}
            officeName={props.Branch_Location}
            off_address={props.Address}
            branchLink={props.branchLink}
          />
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ContactCard
