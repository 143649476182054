import React from 'react';
import { Link } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import './OpeningCard.scss';

const OpeningCard = (props) => {
	return (
		<div className="open-card">
			<h3>Opening Hours</h3>
			<ul class="contact-box">
				{props.Add_Open_Hours?.map((item, i) => {
					return (
						<li>
							<strong>{item.Day}</strong>
							<span>{item.Time}</span>
						</li>
					)
				})}
			</ul>
			
			{props.Licence_Number&&
			<div className='licence-number-office'>
				<p><span>PSRA Licence No :</span> {props.Licence_Number}</p>
			</div>
			}
		</div>
	)
}

export default OpeningCard
