import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import contactImg1 from "../../../images/contact-4.jpg"
import contactImg2 from "../../../images/contact-5.jpg"
import "./contactSidebar.scss"
import { useStaticQuery, graphql } from "gatsby"
import GetURL from "@Components/common/site/get-url"
import Modal from "react-bootstrap/Modal"
import TeamForm from "@Components/forms/teamcontact"
import OfficeForm from "@Components/forms/officecontact"
import NoImage from "../../../images/no-image.png"
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform"
import ContactForm from "../../forms/contact"
const ContactSidebar = props => {
  const [show, setShow] = useState(false)

  const [showModal, setShowModal]=useState(false)
  const handleClose = () => setShow(false)
  const handleShow = event => {
    setShow(true)
  }

  const [showofc, setShowofc] = useState(false)
  const handleCloseofc = () => setShowofc(false)
  const handleShowofc = event => {
    setShowofc(true)
  }

  const handleValuation=()=>{
      setShowModal(true)
  }

  const closeValuation=()=>{
    setShowModal(false)
  }
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        globalConfig(publicationState: LIVE) {
          Office_Detail_Contact {
            Cta_Label
            Phone_No
            Cta_Url {
              Alias
            }
            Cta_Label1
            Cta_Url1 {
              Alias
            }
          }
        }
      }
    }
  `)

  //console.log("Alias ==>", props.Alias);

  //console.log("Contact_No", props.Contact_No)

  const branchData = {
    pageUrl: props.pageUrl,
    branchDetailNum: props.Contact_No,
  }

  // const getPath=typeof window !=="undefined"&&window.location.pathname;
  // const valuationPath=getPath&&getPath.includes("/services/valuations/");

  useEffect(()=>{
    const getId=document.getElementById("leesonparkvals");
  const scrollTop=()=>{
    window.scrollTo({
      top: 100,
      left: 0,
      behavior: 'smooth'
    })
  }
  getId?.addEventListener("click", scrollTop)
  },[])
  

  return (
    <div className="sidebar-contact sidebar-bk 1">
      <div
        className={
          props?.Choose_Team?.length > 0
            ? "dealer-cta bottom-fixed-btn call-bk d-xl-flex"
            : "dealer-cta bottom-fixed-btn call-bk d-xl-flex no-deelar"
        }
      >
        <div className="btn-wrap">
          <Modal
            show={showofc}
            centered={true}
            onHide={handleCloseofc}
            backdrop="static"
            keyboard={false}
            dialogClassName="modal-90w modal-form modal-form-buy-sell-rent reviewPopup"
          >
            <Modal.Header closeButton className="contact-close-btn">
              <Modal.Title className="w-100">
                Email to {props.Office_Name}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="form">
              <OfficeForm
                toemail={props.Email}
                officeName={props.Office_Name}
                branchPhoneNum={props.Contact_No}
                off_address={props.off_address}
              />
            </Modal.Body>
          </Modal>
          {props.officedetailspage ? (
            <a
              href="javascript:;"
              onClick={() => handleShowofc()}
              className="btn"
            >
              contact us
            </a>
          ) :
          (props.Alias==="why-dng-valuations" || props.Alias==="valuations") ?
          <a
          href="javascript:;"
          onClick={() => handleValuation()}
          className="btn"
        >
          contact us
        </a>
          :(
            <GetURL
              class="btn"
              label={
                data?.glstrapi?.globalConfig?.Office_Detail_Contact?.Cta_Label
              }
              alias={
                data?.glstrapi?.globalConfig?.Office_Detail_Contact?.Cta_Url
                  ?.Alias
              }
            />
          )}
        </div>
        
        
        <div className="btn-wrap">
          {props.Alias === "fair-deal-guide" ? (
            <GetURL
              class="btn outline-btn"
              label={
                data?.glstrapi?.globalConfig?.Office_Detail_Contact?.Cta_Label1
              }
              alias={"fair-deal-valuation"}
            />
          ) : props.Alias === "dng-probate-guide" ? (
            <GetURL
              class="btn outline-btn"
              label={
                data?.glstrapi?.globalConfig?.Office_Detail_Contact?.Cta_Label1
              }
              alias={"probate-valuation"}
            />
          ) : 
         (
            <GetURL
              class="btn outline-btn"
              stateData={{ branchDetails: branchData }}
              label={
                data?.glstrapi?.globalConfig?.Office_Detail_Contact?.Cta_Label1
              }
              alias={
                data?.glstrapi?.globalConfig?.Office_Detail_Contact?.Cta_Url1
                  ?.Alias
              }
            />
          )}
        </div>
        
          
        <span className="contact-no">
          <span className="desk-only">or call us on </span>
          {props.Contact_No ? (
            <a className="call-icon-only" href={"tel:" + props.Contact_No}>
              <span className="desk-only">{props.Contact_No}</span>
            </a>
          ) : (
            <a
              className="call-icon-only"
              href={
                "tel:" +
                data?.glstrapi?.globalConfig?.Office_Detail_Contact?.Phone_No
              }
            >
              <span className="desk-only">
                {data?.glstrapi?.globalConfig?.Office_Detail_Contact?.Phone_No}
              </span>
            </a>
          )}
        </span>

        {/* Modal for why dng valuation */}
        <Modal
            show={showModal}
            centered={true}
            onHide={closeValuation}
            backdrop="static"
            keyboard={false}
            dialogClassName="modal-90w modal-form modal-form-buy-sell-rent reviewPopup"
          >
            <Modal.Header closeButton className="contact-close-btn">
              <Modal.Title className="w-100">
                Contact us
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="form">
                <ContactForm to_email_id="leesonparkvals@dng.ie" valuationContact={true}/>
            </Modal.Body>
          </Modal>

      </div>
      {props?.Choose_Team?.length > 0 && (
        <div className="dealer-info">
          {props?.Choose_Team?.map((item, i) => {
            let processedImages = JSON.stringify({})
            if (item?.imagetransforms?.Image_Transforms) {
              processedImages = item?.imagetransforms.Image_Transforms
            }
            return (
              <div className="dealer-wrap">
                <div className="img-wrap">
                  {/* <img src={item?.Image?.url} alt={item?.Image?.alternativeText} /> */}
                  {item?.Image?.url ? (
                    <ImageTransform
                      imagesources={item?.Image?.url}
                      renderer="srcSet"
                      imagename="team.Image.smalltail"
                      attr={{
                        alt: item?.Image?.alternativeText
                          ? item?.Image?.alternativeText +
                            " - DNG Estate Agents"
                          : item?.Name + " - DNG Estate Agents",
                        className: "",
                      }}
                      imagetransformresult={processedImages}
                      id={item?.id}
                    ></ImageTransform>
                  ) : (
                    <img src={NoImage} alt={item?.Image?.alternativeText} />
                  )}
                </div>
                <div className="dealer-right">
                  <span className="dealer-name">{item?.Name}</span>
                  <span className="dealer-position">{item?.Designation}</span>
                  <span className="dealer-contact">
                    <a href={"tel:" + item?.Phone}>{item?.Phone}</a> /{" "}
                    <a href="javascript:;" onClick={() => handleShow()}>
                      Email
                    </a>
                  </span>
                </div>
                <Modal
                  show={show}
                  centered={true}
                  onHide={handleClose}
                  backdrop="static"
                  keyboard={false}
                  dialogClassName="modal-90w modal-form modal-form-buy-sell-rent reviewPopup"
                >
                  <Modal.Header closeButton className="contact-close-btn">
                    <Modal.Title className="w-100">
                      Email to {item?.Name}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="form">
                    <TeamForm
                      toemail={item?.Email}
                      officeNumber={item?.Phone}
                      teamMember={item?.Name}
                    />
                  </Modal.Body>
                </Modal>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default ContactSidebar
