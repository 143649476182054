import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import Accordion from "react-bootstrap/Accordion"
import "./OfficeProperty.scss"
import ReactMarkdown from "react-markdown"
import _ from "lodash"
import { Property_Data } from "../../../queries/common_use_query"

import {
  Commercial_Sale_Page_Url,
  ToRent_Page_Url,
  ForSale_Page_Url,
} from "@Components/common/site/constants"
const OfficeProperty = props => {
  const [activeId, setActiveId] = useState("")

  const [hasSale, setHasSale]=useState(false)
  const [hasrent, setHasRent]=useState(false)
  const [hasCommercial,setHasCommercial]=useState(false)
  const [hasCommercialRent, setHasCommercialRent]=useState(false)


  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null)
    } else {
      setActiveId(id)
    }
  }

  var Office_id = _.toLower(props.Office_id);
  var home_id=props.HomeId



    const propDetails = (Office_id) => {
    const { loading, error, data } = Property_Data(Office_id)
    return data
  }
  let propData= propDetails(props.Office_id?props.Office_id:home_id)

 useEffect(()=>{
 propData?.properties?.map(property=>{
  if(property.department==="residential"){
    if(property.status==="For Sale"||property.status==="Sale Agreed"){
    setHasSale(true)

    }
    else if(property.status==="To Let"||property.status==="Let Agreed"){
      console.log("lettt")
      setHasRent(true)
    }
  }
  if(property.department==="commercial"){
    if(property.status==="For Sale"||property.status==="Sale Agreed"){
      setHasCommercial(true)
      }
    if(property.status==="To Let"||property.status==="Let Agreed"){
      setHasCommercialRent(true)
    }
  }
})

 },[propData])

  return (
    <React.Fragment>
      <div className="office-property">
        <div className="">
          {(hasSale||hasrent||hasCommercial||hasCommercialRent) &&
          <>
          <h3>{props.Branch_Location} Office Properties</h3>
          <div className="property-btn-wrap">
            {hasSale&&
            <Link
              to={`/property/for-sale/in-${props.slug}/dng-office-${Office_id?Office_id:home_id}/`}
              onClick={() => window.scrollTo(0, 0)}
              className="property-btn"
            >
              property for sale
            </Link>
              }

              {hasrent&&
            <Link
            to={`/property/to-rent/in-${props.slug}/dng-office-${Office_id?Office_id:home_id}/`}
              onClick={() => window.scrollTo(0, 0)}
              className="property-btn"
            >
              property to rent
            </Link>
              }
              {hasCommercial&&
            <Link
              to={`/commercial-property/for-sale/in-${props.slug}/dng-office-${Office_id?Office_id:home_id}/`}
              onClick={() => window.scrollTo(0, 0)}
              className="property-btn"
            >
              commercial for sale
            </Link>
            }
             {hasCommercialRent&&
            <Link
              to={`/commercial-property/to-rent/in-${props.slug}/dng-office-${Office_id?Office_id:home_id}/`}
              onClick={() => window.scrollTo(0, 0)}
              className="property-btn"
            >
              commercial to rent
            </Link>
            }
          
          </div>
          </>
            }

          {props.About && (
            <>
              <h3>About this Office</h3>
              <ReactMarkdown source={props.About} allowDangerousHtml />
            </>
          )}
        </div>
        {props.Toggle_Box?.length > 0 && (
          <div className="accordion-wrap">
            <Accordion defaultActiveKey={activeId}>
              <ul>
                {props.Toggle_Box?.map((item, i) => {
                  return (
                    <li>
                      <Accordion.Toggle
                        onClick={() => toggleActive(i + 1)}
                        className={
                          activeId === i + 1
                            ? "accordion-header minus"
                            : "accordion-header"
                        }
                        eventKey={i + 1}
                      >
                        {item.Tilte}
                        <i className="icon-plus"></i>
                        <i className="icon-minus"></i>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={i + 1}>
                        <div className="accordion-content">
                          <ReactMarkdown
                            source={item.Content}
                            allowDangerousHtml
                          />
                        </div>
                      </Accordion.Collapse>
                    </li>
                  )
                })}
              </ul>
            </Accordion>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default OfficeProperty
